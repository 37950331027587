&nbsp; &nbsp; &nbsp; &nbsp; <input style="position:absolute" type="image" src="assets/image/flag_eng.png" (click)="changeLanguageReg('ita')"/>
&nbsp;<input style="position:absolute; margin-left: 35px;" type="image" src="assets/image/flag_ita.png" (click)="changeLanguageReg('eng')"/>&nbsp;
<div *ngIf="this.flagTextEng">
<div id="parentdiv" #parentdiv>
    <div class="row justify-content-center">
        <div class="col-md-8 register-employee">
            <div class="card card-outline-secondary">
                <div class="card-header">
                    <h2 >THE DETERMINATION OF OXIDATIVE STRESS THROUGH H&D BLOOD TEST</h2>
                </div>

                <div class="card-body">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar" style='text-align:justify'> 
                        The evaluation of the redox balance is extremely important. The redox balance depends on the concentration of oxidants
                        but also on the body's ability to counteract them, that is, on its antioxidant capacity. Consequently, the optimum is
                        to combine a test capable of evaluating the oxidizing component and a test capable of evaluating the antioxidant component,
                        thus providing a global assessment. Blood is the biological fluid to be examined because, coming into contact with all the
                        body compartments, it is an excellent index of the global oxidative status.
                        <br><br>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The tests: d-ROMs AND BAP</h3><br>
                        <div>
                            <img src="assets/image/1.jpg" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                        The first generation-tests for the determination of oxidative status were the d-ROMs test and the BAP test. The d-ROMs
                        is the test dedicated to the oxidative component as it was developed for the determination of
                        peroxides, while the BAP test is the test dedicated to the total antioxidant power in the form of iron-reducing capacity.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">d-ROMs TEST - The first generation</h3><br>
                        The direct determination of free radicals is not easy to implement, especially in vivo. It was therefore decided to monitor
                        oxidative stress levels with a very significant marker of radical oxidative processes, such as the concentration of peroxides.
                        The determination of peroxides is relevant because peroxides are among the first and most important intermediates of most of
                        oxidative processes, unlike most other oxidative markers that are usually their final products. For this reason, the determination
                        of peroxides not only measures a full-blown damage but also a potential damage. Therefore, it enables more promptly interventions.
                        The predictivity of the peroxide analysis seems to be confirmed by some data on the relationship between d-ROMs values ​​and concentration
                        of C- reactive protein, a well-known marker of full-blown inflammation [1]. The value of d-ROMs tends to rise before that of the C-reactive
                        protein, in a still asymptomatic pre-inflammatory phase [2, 3, 4,5]. Other confirmations of the importance of the determination of peroxides
                        have been highlighted by studies on isoprostanes and on MDA [6].
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">d-ROMs FAST test - The new generation</h3><br>
                        <div>
                            <img src="assets/image/2.jpg" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 50%;
                                    ">

                            The d-ROMs FAST (patented) is the fastest version of the d-ROMs test, which gives the same result, with the same diagnostic validity in half 
                            the time, meaning in only 2 minutes and 30 second, instead of 5 minutes. The d-ROMs test and the d-ROMs fast test use the same chemical principle.
                            Both exploit the ability of peroxides degraded by alkoxyl and peroxyl radicals in the presence of transition metal ions (Fenton reaction) and the
                            ability of the generated radicals to react with DEPPD forming its radical-cation. Both tests work in kinetic mode, that is, they relate the coloring
                            velocity of the solution to the concentration of peroxides in the sample.
                            <br><br>
                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">BAP TEST - The first generation</h3><br>
                        The BAP (Biological Antioxidant Potential), is based on the ability of the blood plasma to reduce the ferric ion to ferrous. BAP can be considered
                        a direct descendant of the FRAP method with which it shares its chemical principle [7]. Thanks to its simple method and the stability of the reagents
                        used, BAP is easier to use than FRAP and more suitable for clinical use.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">PAT Test - The new generation</h3><br>
                        <div>
                            <img src="assets/image/3.jpg" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                        While the evolution of the d-ROMs to d-ROMs FAST mostly responds to clinical needs for faster analysis, the evolution of the BAP to PAT (Plasma Antioxidant Test)
                        (patented) has been caused, in addition to speeding up (5 minutes to 1 minute), also and especially from the need to solve a serious analytical problem.
                        The BAP test, like any test that requires a colorimetric determination of ferric ions, suffers the interference of phosphate ions in blood. Phosphate ions trigger
                        a competitive reaction, the result of which is a very remarkable overestimation of the antioxidant capacity. A suitable zirconium salt eliminates the interference
                        originated by phosphates. The better accuracy of PAT compared to BAP makes it the test of choice for the evaluation of antioxidants in blood [8].
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The instrument - FRAS (Free Radical Analytical System)</h3><br>
                        <div>
                            <img src="assets/image/4.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                        Dedicated compact photometers (FRAS) have been developed to be easy to use, manage and maintain in the clinical practice. The latest generation is FRAS 5
                        that incorporates a centrifuge, necessary for the preparation of samples without any other instrumentation, a reading cell thermostated at 37 ° C, necessary
                        for the incubation at controlled temperature of the tests, and a printer for immediate printing of the results. FRAS 5 includes many programs that enable 
                        the user to execute step by step the various tests and calculate various indexes. FRAS 5 can be used practically by anyone in the health sector even without
                        previous laboratory experience.
                        <br><br>
                        </div>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">HOW TO INTERVENE</h3><br>
                        <div>
                        It is therefore extremely interesting to be able to intervene on patients in strong oxidative stress condition in order to normalize their redox parameters towards a balanced condition.  
                        Achieving such a result would be a clinically, humanly and economically extraordinary achievement.
                        <br><br>
                        </div>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">References</h3><br>
                        <div>
                            1. HIROSE, Hiroshi, et al. Relations between serum reactive oxygen metabolites (ROMs) and various inflammatory and metabolic parameters in a Japanese population. Journal of atherosclerosis and thrombosis, 2009, 16.2: 77-82.<br>
                            2. KOTANI, Kazuhiko; TANIGUCHI, Nobuyuki. Correlation between high-sensitivity C-reactive protein and reactive oxygen metabolites during a one-year period among asymptomatic subjects. Journal of clinical medicine research, 2012, 4.1: 52.<br>
                            3. KAMEZAKI, Fumihiko, et al. Derivatives of reactive oxygen metabolites correlates with high-sensitivity C-reactive protein. Journal of atherosclerosis and thrombosis, 2008, 15.4: 206-212.<br>
                            4. KAPTOGE, S. et al. C-reactive protein, fibrinogen, and cardiovascular disease prediction, N. Engl. J. Med. 2012, 367: 1310–1320.<br>
                            5. CORSETTI, Roberto, et al. Redox state in professional cyclists following competitive sports activity. The Open Sports Medicine Journal, 2012, 6.1.<br>
                            6. CIGHETTI, Giuliana, et al. Oxidative status in different settings and with different methodological approaches compared by Receiver Operating Characteristic curve analysis. Clinical biochemistry, 2015, 48.1-2: 73-78.<br>
                            7. BENZIE, Iris FF; STRAIN, John J. The ferric reducing ability of plasma (FRAP) as a measure of “antioxidant power”: the FRAP assay. Analytical biochemistry, 1996, 239.1: 70-76.<br>
                            8. BENEDETTI Serena, et al.,  Determination of Plasma Antioxidant Power in Capillary Blood through the Innovative system PAT (Plasma Antioxidant Test). Free Radicals and Antioxidants. 2018; 8(2):149-152<br> 
                        <br><br>
                        </div>
                    </div>
                    <div>
                        <img src="assets/image/5.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">
                        <div style="text-align: center; color: rgb(103, 170, 197);">
                            <h6>H&D srl<br></h6>
                            Strada Langhirano 264/1A - 43124 Parma - Italy<br>
                            Telefono: +39.0521.462607 - Fax: +39.0521.407083<br>
                            www.hedsrl.it info@hedsrl.it <br><br>
                        </div>
                        <div style="color: rgb(103, 170, 197);">
                        <i>
                            This document is the property of H&D. <br>
                            Copying and reproduction in any form, even partial, of the contents is prohibited. <br>
                            Redestribution and publication of content not expressly authorized by the author is prohibited.
                        </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<div *ngIf="this.flagTextIta">
    <div id="parentdiv" #parentdiv>
        <div class="row justify-content-center">
            <div class="col-md-8 register-employee">
                <div class="card card-outline-secondary">
                <div class="card-header">
                    <h2 >LA DETERMINAZIONE DELLO STRESS OSSIDATIVO CON I TEST SUL SANGUE DI H&D</h2>
                </div>

                <div class="card-body">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar" style='text-align:justify'> 
                        E’ di estrema importanza la valutazione dell’equilibrio redox nella sua complessità. L’equilibrio redox dipende sì dalla
                        concentrazione di ossidanti e pro-ossidanti ma anche dalla capacità dell’organismo di contrastarli, ossia da quella che viene
                        descritta come capacità antiossidante. Di conseguenza si è sempre cercato di abbinare ad un test, in grado di valutare la componente
                        ossidante, un test in grado di valutare la componente antiossidante per fornire una valutazione globale. Sempre nella logica di una
                        valutazione globale dell’equilibrio redox, si è scelto come fluido biologico da esaminare il sangue. Questo perché, entrando esso in
                        contatto con tutti i vari compartimenti del corpo è un ottimo indice dello status ossidativo globale.
                        <br><br>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">I primi test: d-ROMs AND BAP</h3><br>
                        <div>
                            <img src="assets/image/1.jpg" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                        I test di prima generazione per la determinazione dello status ossidativo sono stati il d-ROMs test ed il BAP test. Il d-ROMs test è
                        l’analisi dedicata alla porzione ossidativa in quanto è stato sviluppato per la determinazione dei perossidi, mentre il BAP test, dedicato
                        alla porzione antiossidante, è una metodica della classe dei determinanti la capacità ferro riducente.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">d-ROMs TEST – La prima generazione</h3><br>
                        La determinazione diretta dei radicali liberi non è di semplice attuazione, specialmente in vivo. Si è quindi pensato di   utilizzare un marker
                        di stress ossidativo significativo dei processi ossidativi radicalici in corso, quale può essere la concentrazione dei perossidi. Ulteriore importanza
                        della determinazione dei perossidi risiede nel fatto che, al contrario della maggior parte dei marker di stress ossidativo, i perossidi non sono i
                        prodotti finali dei processi ossidativi ma sono tra i primi e più importanti intermedi della maggior parte di questi processi. Questo vuole dire che la
                        determinazione dei perossidi non misura solo un danno conclamato ma anche un danno potenziale e quindi determina la possibilità di intervenire più
                        tempestivamente. La predittività dell’analisi dei perossidi sembra essere confermata da alcuni dati preliminari sulla relazione tra valori di d-ROMs e 
                        concentrazione di proteina C-reattiva, noto marker di infiammazione conclamata [1]. Si è notato che il valore di d-ROMs tende ad innalzarsi prima di
                        quello della proteina C-reattiva, in una fase pre-infiammatoria ancora asintomatica [2, 3, 4, 5]. Altre conferme dell’importanza della determinazione dei
                        perossidi sono state evidenziate da studi su isoprostani e su MDA [6].
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">d-ROMs FAST test – La nuova generazione</h3><br>
                        <div>
                            <img src="assets/image/2.jpg" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 50%;
                                    ">

                            Il d-ROMs FAST è la versione veloce del d-ROMs test, ossia dà il medesimo risultato, con la medesima validità diagnostica nella metà del tempo,
                            vale a dire in soli 2 minuti e trenta secondi anziché in 5 minuti. Il d-ROMs test e il d-ROMs fast test sfruttano il medesimo principio chimico.
                            Entrambi sfruttano la capacità dei perossidi di degradarsi a radicali alcossilici e perossilici in presenza di ioni metallici di transizione (reazione
                            di Fenton) e la capacità di questi radicali così generati di reagire con DEPPD formando il radical-catione della medesima. Entrambi i test funzionano
                            in modalità cinetica ossia correlano la velocità di colorazione della soluzione alla concentrazione di perossidi nel campione. Il d-ROMs fast test è
                            brevettato.
                            <br><br>
                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">BAP TEST – La prima generazione</h3><br>
                        Il BAP (Biological Antioxidant Potential), è basato sulla capacità del plasma sanguigno di ridurre lo ione ferrico a ferroso. Il BAP può essere considerato
                        un diretto discendente della metodica FRAP [7] con il quale ne condivide il principio chimico. Grazie alla sua semplice metodica e alla stabilità dei reagenti
                        utilizzati il BAP risulta di più semplice utilizzo rispetto al FRAP e di più adatto uso clinico.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">PAT Test – La nuova generazione</h3><br>
                        <div>
                            <img src="assets/image/3.jpg" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                        Se l'evoluzione del d-ROMs a d-ROMs FAST rispondeva perlopiù ad esigenze cliniche di velocizzazione delle analisi, l’evoluzione del BAP a PAT (Plasma
                        Antioxidant Test) è stata causata, oltre che velocizzare da 5 a 1 minuto, dall’esigenza di risolvere un serio problema analitico. Si è scoperto, infatti,
                        che il BAP, come tutti i test in cui è necessaria la determinazione colorimetrica dello ione ferrico, soffre l’interferenza degli ioni fosfato. Gli ioni
                        fosfato innescano una reazione competitiva, il cui risultato è una notevole sovrastima della capacità antiossidante. L’aggiunta di un opportuno sale di
                        zirconio nella giusta quantità ha permesso di eliminare l’interferenza originata dai fosfati (brevettato). La migliore esattezza del PAT rispetto al BAP
                        lo rende il test di scelta nella valutazione degli antiossidanti [8].
                        <br><br>
                        
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Gli strumenti FRAS (Free Radical Analytical System)</h3><br>
                        <div>
                            <img src="assets/image/4.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                        I FRAS sono fotometri dedicati e compatti che sono stati sviluppati da H&D per una gestione semplificata ma precisa nella pratica clinica. L’ultima generazione
                        è rappresentata dal FRAS 5 che incorpora: una centrifuga, necessaria per la preparazione dei campioni senza l’uso di alcuna altra strumentazione, una
                        cella di lettura termostata a 37°C, necessaria per l’incubazione a temperatura controllata dei test, e una stampante per la stampa immediata dei
                        risultati ottenuti. Sulla macchina sono già caricati vari programmi in grado di seguire e guidare passo passo l’utente finale nell’esecuzione dei
                        vari test e nel calcolo di vari indici. La particolare configurazione della macchina ne garantisce l’utilizzabilità praticamente a chiunque in
                        ambito sanitario anche senza pregresse esperienze di laboratorio.
                        <br><br>
                        </div>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">COME INTERVENIRE</h3><br>
                        <div>
                        E’ perciò estremamente interessante ed utile potere intervenire su pazienti in condizioni di stress ossidativo al fine di normalizzare i loro parametri redox e ottenere una condizione di bilanciamento redox.  
                        Il conseguimento di tale risultato sarebbe uno straordinario obiettivo sia dal punto di vista clinico che umano ed economico.
                        <br><br>
                        </div>
                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Riferimenti</h3><br>
                        <div>
                            1. HIROSE, Hiroshi, et al. Relations between serum reactive oxygen metabolites (ROMs) and various inflammatory and metabolic parameters in a Japanese population. Journal of atherosclerosis and thrombosis, 2009, 16.2: 77-82.<br>
                            2. KOTANI, Kazuhiko; TANIGUCHI, Nobuyuki. Correlation between high-sensitivity C-reactive protein and reactive oxygen metabolites during a one-year period among asymptomatic subjects. Journal of clinical medicine research, 2012, 4.1: 52.<br>
                            3. KAMEZAKI, Fumihiko, et al. Derivatives of reactive oxygen metabolites correlates with high-sensitivity C-reactive protein. Journal of atherosclerosis and thrombosis, 2008, 15.4: 206-212.<br>
                            4. KAPTOGE, S. et al. C-reactive protein, fibrinogen, and cardiovascular disease prediction, N. Engl. J. Med. 2012, 367: 1310–1320.<br>
                            5. CORSETTI, Roberto, et al. Redox state in professional cyclists following competitive sports activity. The Open Sports Medicine Journal, 2012, 6.1.<br>
                            6. CIGHETTI, Giuliana, et al. Oxidative status in different settings and with different methodological approaches compared by Receiver Operating Characteristic curve analysis. Clinical biochemistry, 2015, 48.1-2: 73-78.<br>
                            7. BENZIE, Iris FF; STRAIN, John J. The ferric reducing ability of plasma (FRAP) as a measure of “antioxidant power”: the FRAP assay. Analytical biochemistry, 1996, 239.1: 70-76.<br>
                            8. BENEDETTI Serena, et al.,  Determination of Plasma Antioxidant Power in Capillary Blood through the Innovative system PAT (Plasma Antioxidant Test). Free Radicals and Antioxidants. 2018; 8(2):149-152<br> 
                        <br><br>
                        </div>
                    </div>
                    <div>
                        <img src="assets/image/5.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">
                        <div style="text-align: center; color: rgb(103, 170, 197);">
                            <h6>H&D srl<br></h6>
                            Strada Langhirano 264/1A - 43124 Parma - Italy<br>
                            Telefono: +39.0521.462607 - Fax: +39.0521.407083<br>
                            www.hedsrl.it info@hedsrl.it <br><br>
                        </div>
                        <div style="color: rgb(103, 170, 197);">
                        <i>
                            Questo documento è di proprietà di H&D. <br>
                            La copia e la riproduzione in qualsiasi forma, anche parziale, dei contenuti è proibita. <br>
                            La ridistribuzione e la pubblicazione dei contenuti non espressamente autorizzata dall'autore è proibita.
                        </i>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>    

