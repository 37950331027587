<!--
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1">
<title>Bootstrap Flat Modal Login Modal Form</title>
<link href="https://fonts.googleapis.com/css?family=Roboto|Varela+Round" rel="stylesheet">
<style type="text/css">

</style>
</head>
<body>

<div id="myModal" >
	<div class="modal-dialog modal-login">
		<div class="modal-content">
			<div class="modal-header">				
				<h4 class="modal-title">Sign In</h4>
			</div>
			<div class="modal-body">          
          <form (submit)="login()">
            <div class="form-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input type="email" class="form-control" name="email" placeholder="Inserire Email" [(ngModel)]="credentials.email">
            </div>
            <div class="form-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="credentials.password">
            </div>
            <div class="row">
              <div class="col text-center">
                <button type="submit" class="btn btn-default">Sign in!</button>
              </div>  
            </div>      
          </form>

					<p class="hint-text"><a routerLink="/resetpassword">Forgot Password?</a></p>
			</div>
			<div class="modal-footer">Don't have an account? <a routerLink="/register">Create one</a></div>
		</div>
  </div>
</div>     
</body>
</html> 
-->
<!--
<div class="container-fluid form">
    <div class="row form-row ">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card my-5">
                <div class="card-body">-->
<div id="myModal" >
    <div class="modal-dialog modal-login">
        <div class="modal-content">
            <div class="modal-header">				
                <h4 class="modal-title">Reset Password</h4>
            </div>
            <div class="modal-body">                     
                <div>
                    <div id="errorMsg" *ngIf="errorMessage">
                        <span>{{errorMessage}}</span>
                    </div>
                    <div id="successMsg" *ngIf="successMessage">
                        <span>{{successMessage}}</span>
                    </div>
                    <form action="" [formGroup]="RequestResetForm" (ngSubmit)="RequestResetUser(RequestResetForm)">
                        <div class="form-group">
                            <input _ngcontent-c0="" class="form-control form-control-lg" placeholder="Inserire Mail"
                                type="text" id="email" formControlName="email" />
                            <span *ngIf="!RequestResetForm.get('email').valid && !IsvalidForm"
                                class="help-block">Please enter a valid email!</span>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class=" btn btn-primary">Reset Password</button>
                                </div>  
                            </div>         
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
