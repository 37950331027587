import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// Component
import { LoginComponent } from './component/login/login.component';
import { ProfileComponent } from './component/profile/profile.component';
import { RegisterComponent } from './component/register/register.component';

import { AppComponent } from './app.component';
import { NavBarComponent } from './component/nav-bar/nav-bar.component';
import { FooterComponent } from './component/footer/footer.component';
import { VoidPageComponent } from './component/void-page/void-page.component';

import { RequestresetComponent } from './component/requestreset/requestreset.component';
import { ResponseresetComponent } from './component/responsereset/responsereset.component';

//BookOfRef Component
import { HomeBookComponent } from './component/home-book/home-book.component'
import { IstruzioniBookComponent } from './component/istruzioni-book/istruzioni-book.component';

// service
import { AuthGuardService } from './service/auth-guard.service';

// pipe
import { Ng2SearchPipeModule} from 'ng2-search-filter';
import { NgPipesModule } from 'ngx-pipes';
import { BookPipe } from './pipe/book-pipe';
import { ProvaPipe } from './pipe/prova.pipe';

// grafici
import { ChartsModule } from 'ng2-charts';

//module
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// rooting per host
import {HashLocationStrategy,LocationStrategy} from '@angular/common';

//old
import { NgpSortModule } from "ngp-sort-pipe";
import { HedbloodtestsComponent } from './component/hedbloodtests/hedbloodtests.component';




@NgModule({
  declarations: [
    ProfileComponent,
    RegisterComponent,
    AppComponent,
    LoginComponent,
    NavBarComponent,
    FooterComponent,
    VoidPageComponent,
    RequestresetComponent,
    ResponseresetComponent,
    BookPipe,
    ProvaPipe,
    HomeBookComponent,
    IstruzioniBookComponent,
    HedbloodtestsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgpSortModule,
    NgPipesModule,
    ChartsModule,
    NgbModule
  ],
  providers: [
    AuthGuardService,
  {provide: LocationStrategy, useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
