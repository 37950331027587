import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



///import { LoginComponent } from './component/#old/login/login.component';
//
//import { LogoutComponent } from './component/#old/logout/logout.component';
//import { RouteGuardService } from './service/route-guard.service';

import { VoidPageComponent } from './component/void-page/void-page.component';

import { ProfileComponent } from './component/profile/profile.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { RequestresetComponent } from './component/requestreset/requestreset.component';
import { ResponseresetComponent } from './component/responsereset/responsereset.component';
import { AuthGuardService } from './service/auth-guard.service';
//import { BookHomeComponent } from './component/2-book-of-ref/book-home/book-home.component';
//import { BookHomeComponent } from './component/home-book/home-book.component';
import { HomeBookComponent } from './component/home-book/home-book.component';
import { IstruzioniBookComponent } from './component/istruzioni-book/istruzioni-book.component';
import { HedbloodtestsComponent } from './component/hedbloodtests/hedbloodtests.component';



const routes: Routes = [
  /*
  {path: '', component: LoginComponent },
  {path: 'login', component: LoginComponent },
  {path: 'register', component: RegisterComponent },
  {path: 'resetpassword', component: RequestresetComponent},
  {path: 'response-reset-password/:token', component: ResponseresetComponent},
  {path: 'profile', component: ProfileComponent, canActivate:[AuthGuardService]},
*/
  {path: '', component: HomeBookComponent },
  {path:'userguide', component : IstruzioniBookComponent},
  {path: 'hedbloodtests', component : HedbloodtestsComponent},
  {path:'**', component : VoidPageComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
