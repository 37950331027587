import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {var1} from '../variables';


@Injectable({
  providedIn: 'root'
})
export class ApiBookService {

 //baseUri:string = '/book-api';
 //baseUri:string = 'http://localhost:4000/book-api';
 baseUri:string = var1+'/book-api';
  
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { 

  }

  // Create
  createArticle(data): Observable<any> {
    let url = `${this.baseUri}/create`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  // Get all employees
  getArticles() {
    return this.http.get(`${this.baseUri}/read-all-articles`);
  }
  
  // Get articolo evidenza
  getArticleEv() {
    return this.http.get(`${this.baseUri}/articolo-evidenza`);
  }

  // Get Category
  getCategory(category) {
    return this.http.get(`${this.baseUri}/get-category/${category}`);
  }

  // Get 
  getArticle(id): Observable<any> {
    let url = `${this.baseUri}/read/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.errorMgmt)
    )
  }

  getArticlesFilter(search,autore,agefrom,ageto,cat1,cat2,typefilter) {
    //console.log("dati filtro: "+AuthenticatorResponse)

    var Url=`${this.baseUri}/read-filter-articles/${search}/${autore}/${agefrom}/${ageto}/${cat1}/${cat2}/${typefilter}`
    console.log("URL: "+Url)

    return this.http.get(Url);
  }

  // Send Email
  sendMail(mail,testo,idart,titoloart): Observable<any> {
    if(idart || titoloart){
      testo+="<br><br><b>ID Articolo</b>: "+idart+"<br><b>Titolo Articolo</b>: "+titoloart
    }
    
    console.log("FrontEnd SendReport");
    console.log("Mail: "+mail);
    console.log("Testo: "+testo);
    
    let url = `${this.baseUri}/contact-us`;
    let params = new HttpParams()
      .set('mail', mail)
      .set('testo', testo)
   

    return this.http.post(url,params);

  }

  // Save Mail
  saveMail(mail): Observable<any> {
    
    console.log("FrontEnd SendReport");
    console.log("Mail: "+mail);
    
    let url = `${this.baseUri}/insert-mail`;
    let params = new HttpParams()
      .set('mail', mail)
   
    return this.http.post(url,params);
  }
  
  // Share Article
  shareArticle(mail,name,surname,istit,title,auth,ref,doi): Observable<any> {
    
    let url = `${this.baseUri}/share-article`;
    let params = new HttpParams()
      .set('mail', mail)
      .set('name', name)
      .set('surname', surname)
      .set('istit', istit)
      .set('title', title)
      .set('auth', auth)
      .set('ref', ref)
      .set('doi', doi)
   
    return this.http.post(url,params);
  }

  // visite
  incVisite(id_art): Observable<any> {

    console.log("VISITE: "+id_art)
    let url = `${this.baseUri}/update/${id_art}`;
    return this.http.post(url, id_art)
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  // conta articoli
  countArticle(){
    return this.http.get(`${this.baseUri}/conta-articolo`);
  }

  // Error handling 
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
