import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserDetails } from 'src/app/service/authentication.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ApiBookService } from 'src/app/service/book/api-book.service';
//import { ApiWellifeExamService } from 'src/app/service/data/api-wellife-exam.service';
//import { ApiUserService } from 'src/app/service/data/api-user.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormControl , ReactiveFormsModule , FormsModule } from "@angular/forms";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  
  details: UserDetails;
  title = 'appBootstrap';

  submitted = false;

  id="";
    
  successMessage: string;
  errorMessage: string;

  closeResult: string;


  isShown=false;
  isShown2=false;
  email="";
  testoMail="";
  nome="";
  cognome="";
  istitut="";
  titolo="";
  auth="";
  ref="";
  doi="";

  constructor(
    public BasicAuth: AuthenticationService, 
    private modalService: NgbModal, 
    private apiService: ApiBookService,
    //private apiExamService: ApiWellifeExamService,
    //private apiUserService: ApiUserService,
    private router: Router,
    public fb: FormBuilder
    ) {
    }

  ngOnInit(): void {
      }
//---------------------------------------------------------------------------

  onSubmit() {
   
  }  
  
  // Gestione Modal Profilo utente -------------------------------------------------------------------
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'lg'}).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  // Invio mail report
  sendMail() {
    this.isShown=true;
    this.apiService.saveMail(this.email).subscribe(()=>{  
    });
    this.apiService.sendMail(this.email,this.testoMail,"","").subscribe((data)=>{
    });
  }

  shareArticle(){
    this.isShown2=true;
    this.apiService.shareArticle(this.email,this.nome,
    this.cognome,
    this.istitut,
    this.titolo,
    this.auth,
    this.ref,
    this.doi).subscribe(()=>{  
    });
  }
  //----------------------------------------------------------------------------------------------------
  /*
  leggiSerialPort() {

        this.apiExamService.getSerialPort().subscribe((data) => {
        }
      )    
    
  }*/

}
