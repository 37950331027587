
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiBookService } from 'src/app/service/book/api-book.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ConditionalExpr } from '@angular/compiler';
import { empty } from 'rxjs';


@Component({
  selector: 'app-home-book',
  templateUrl: './home-book.component.html',
  styleUrls: ['./home-book.component.css']
})
export class HomeBookComponent implements OnInit {
  //---------------------------------------------------------------------
  form: FormGroup;

  show=true;
  searchText="";
  messaggio_server="";
  levels = [
    "Aesthetic Medicine"
    ,"Andrology"
    ,"Animal Models"
    ,"Anti-aging"
    ,"Antioxidant Supplements"
    ,"Blood and Bone Marrow Diseases"
    ,"Cardiovascular Diseases (before 2015)"
    ,"Cardiovascular Diseases (2015-today)"
    ,"Dentistry"
    ,"Dermatology"
    ,"Diabetology"
    ,"Dietetics"
    ,"Endocrinology"
    ,"Gastroenterology"
    ,"Genetic Diseases"
    ,"Geriatrics"
    ,"Hepatology"
    ,"In Vitro Studies"
    ,"Infectious Diseases"
    ,"Life Quality"
    ,"Life Style"
    ,"Metabolic Syndrome and Obesity"
    ,"Natural Supplements"
    ,"Neonatology and Pediatrics"
    ,"Nephrology"
    ,"Neurology and Psychiatry"
    ,"Obstetrics and Gynecology"
    ,"Occupational Health"
    ,"Oncology"
    ,"Ophthalmology"
    ,"Orthopedics"
    ,"Other"
    ,"Otolaryngology"
    ,"Ozone Therapy and Hyperbaric Medicine"
    ,"Pulmonology"
    ,"Rare Diseases"
    ,"Reproduction and Fertility"
    ,"Rheumatology"
    ,"Sport Medicine"
    ,"Surgery"
    ,"Thermal Medicine"
    ,"Traumatology"
    ,"Urology"
    ,"Validation and Comparative Studies"
    ,"Veterinary Medicine (before 2010)"
    ,"Veterinary Medicine (2010-2014)"
    ,"Veterinary Medicine (2015-2019)"
    ,"Veterinary Medicine (2020-2024)"
    
  ];

  closeResult: string;
  isShown=false;
  email="";
  testoMail="";

  users: any[] = [];
  filteredUsers: any[] = [];

  Articles:any = [];
  ArticleEv:any = [];
  addCat:any = [];
  addCatCount=0
  
  count=0;
  countIf=false;
  foundItem="";
  contArt="";

  sortAut=true;
  sortDate=true;

  urlSearch="";

  showCheck=false;
  public saveUsername:boolean;

  // dati per modale contatti

  modalTitoloArt="";
  modalIdArt="";

  cat1=null
  cat2=null

  @ViewChild('homemodal', { static: true}) content: TemplateRef<any>;

  //--------------------------------------------------------------------
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal, 
    private apiService: ApiBookService) {
    //this.readArticles();
    this.readArtEv();
    //this.countArt();
   }

  ngOnInit(): void {
    
    this.buildForm();
    this.modalService.open(this.content,{size:'lg'});
  }
  public onSaveUsernameChanged(value:boolean){
    this.saveUsername=value
    console.log("check value: "+this.saveUsername)
  }

  

  readArticles(){
    
    this.apiService.getArticles().subscribe((data) => {
     this.Articles = data;
     
    })    
  }
    
  readArtEv(){
    this.apiService.getArticleEv().subscribe((data) => {
      this.ArticleEv = data;
     }) 
  }

  //--- azzero ricerca 
  resetSearch(){
    this.cat1=null
    this.cat2=null
    this.countIf=false
    this.addCatCount=0
    this.sortAut=true;
    this.sortDate=true;
  }
  
  //--- FILTRO AVANZATO ------------------------------------------------------------------------
  buildForm(): void {
    this.form = this.fb.group({
        search: new FormControl(''),
        cat1: new FormControl(''),
        cat2: new FormControl(''),
        agefrom: new FormControl(''),
        ageto: new FormControl(''),
        author: new FormControl('') 
      });
    }

  filterArticles(typefilter,auth) {

    console.log("CERCA AUT1: "+typefilter)

    if(typefilter=="filteraut"){
      this.sortAut=false;
      this.sortDate=true;
    }
    if(typefilter=="filterdata"){
      this.sortAut=true;
      this.sortDate=false;
    }

    if(typefilter=="onlyAuth"){
      this.sortAut=true;
      this.sortDate=true;

      console.log("CERCA AUT: "+auth)
      this.form.controls['author'].setValue(auth);
      /*
      this.form.controls['search'].setValue("");
      this.form.controls['agefrom'].setValue("");
      this.form.controls['ageto'].setValue("");
      this.form.controls['cat1'].setValue("");
      this.form.controls['cat2'].setValue("");*/
    }

    if(!this.form.controls['search'].value) var search=0; else  search=this.form.controls['search'].value;
    if(!this.form.controls['author'].value) var autore=0; else  autore=this.form.controls['author'].value;
    if(!this.form.controls['agefrom'].value) var agefrom=0; else  agefrom=this.form.controls['agefrom'].value;
    if(!this.form.controls['ageto'].value) var ageto=0; else  ageto=this.form.controls['ageto'].value;
    if(!this.form.controls['cat1'].value) var cat1=0; else  cat1=this.form.controls['cat1'].value;
    if(!this.form.controls['cat2'].value) var cat2=0; else  cat2=this.form.controls['cat2'].value;
    if(!typefilter) typefilter=0;

    console.log("ok "+this.form.controls['cat1'].value)

    this.urlSearch='biblios.hedsrl.it/read-filter-articles/'+search+'/'+autore+'/'+agefrom+'/'+ageto+'/'+cat1+'/'+cat2+'/'+typefilter

    console.log("URL: "+this.urlSearch)
    /*
      this.form.controls['search'].value,
      this.form.controls['author'].value,
      this.form.controls['agefrom'].value,
      this.form.controls['ageto'].value,
      this.form.controls['cat1'].value,
      this.form.controls['cat2'].value,*/
    
    this.apiService.getArticlesFilter(
      search,
      autore,
      agefrom,
      ageto,
      cat1,
      cat2,      
      typefilter
      ).subscribe(
        (res) => {
          this.Articles = res;
          this.count=this.Articles.length;  
          
          this.countIf=true;
          
          if(this.count==0){
            this.foundItem="No results found!";
          }
          else{
            this.foundItem="Results found: "+this.count;
          }
          

        }, (error) => {
          console.log("error");
          console.log(error);
        });
  }

  filterOnlyAuth(autore){
    this.apiService.getArticlesFilter(
      0,
      autore,
      0,
      0,
      0,
      0,
      "onlyAuth"
      ).subscribe(
        (res) => {
          this.Articles = res;
          this.count=this.Articles.length;  
          
          this.countIf=true;
          
          if(this.count==0){
            this.foundItem="No results found!";
          }
          else{
            this.foundItem="Results found: "+this.count;
          }
          

        }, (error) => {
          console.log("error");
          console.log(error);
        });
  }

  modo(value){
    console.log("valore: "+value)
    this.apiService.getCategory(value).subscribe((data) => {
      this.addCat = data;
      this.addCatCount=Object.keys(this.addCat).length
      this.cat2=null
      console.log("Categorie add: "+JSON.stringify(this.addCat)+"  "+Object.keys(this.addCat).length)
     }) 
  }

  // Gestione Modal Profilo utente -------------------------------------------------------------------
  open(content,article) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.modalTitoloArt=article.titolo;
    this.modalIdArt=article._id;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  // Invio mail report
  sendMail() {
    this.isShown=true;
    this.apiService.saveMail(this.email).subscribe(()=>{  
    });
    this.apiService.sendMail(this.email,this.testoMail,this.modalIdArt,this.modalTitoloArt).subscribe(()=>{  
    });
  }

  // Incr visite
  incVisite(id_art){

    this.apiService.incVisite(id_art)
    .subscribe(res => {
      console.log('Content updated successfully!')
    }, (error) => {
      console.log(error)
    })
  }

  // Count articoli
  countArt(){
    this.apiService.countArticle().subscribe((data) => {
      this.contArt = JSON.stringify(data);
     }) 
  }
 
  

  handleResponse(response){
    this.messaggio_server = response;
    
  }


}
