
<div class="container">
  <div class="row">
    
     <div class="col-md-6">

      <h1 class="form-signin-heading">Your profile</h1>

      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">Full name</label>
          <p class="form-control-static">{{ details?.name }}</p>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">Email</label>
          <p class="form-control-static">{{ details?.email }}</p>
        </div>
      </div>

    </div>

  </div>
</div>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalPoll-1">Launch
  modal</button>-->

<!-- Modal: modalPoll -->
<div class="modal fade right" id="modalPoll-1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Feedback request
        </p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="far fa-file-alt fa-4x mb-3 animated rotateIn"></i>
          <p>
            <strong>Your opinion matters</strong>
          </p>
          <p>Have some ideas how to improve our product?
            <strong>Give us your feedback.</strong>
          </p>
        </div>

        <hr>

        <p class="text-center">
          <strong>What could we improve?</strong>
        </p>
        <!--Basic textarea-->
        <div class="md-form">
          <textarea type="text" id="form79textarea" class="md-textarea form-control" rows="3"></textarea>
          <label for="form79textarea">Your message</label>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" class="btn btn-primary waves-effect waves-light">Send
          <i class="fa fa-paper-plane ml-1"></i>
        </a>
        <a type="button" class="btn btn-outline-primary waves-effect" data-dismiss="modal">Cancel</a>
      </div>
    </div>
  </div>
</div>
<!-- Modal: modalPoll -->