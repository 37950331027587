<!DOCTYPE html>
<html lang="en">
<head>
</head>
<body>
  <div class="row justify-content-center">
    <div class="col-md-4 register-employee">
      <div class="card card-outline-secondary">
        <div class="card-header">				
          <h4 class="modal-title">Registrazione</h4>
        </div>
        <div class="card-body">
          <!-- Key non valida -->
          <div class="alert alert-danger" role="alert" *ngIf="mexKey">
            Key non valida!
          </div>  
          <!-- Mail non valida -->
          <div class="alert alert-danger" role="alert" *ngIf="mexMail">
            e-Mail esistente!
          </div>    
          
          <form (submit)="register()" #regForm="ngForm" class="needs-validation" novalidate ><!--class="was-validated"-->
            <!-- Ragione Sociale -->
            <div class="form-row">
              <div class="form-group col">
                <input id="name" type="text" class="form-control" name="name" placeholder="Inserire Ragione Sociale" [(ngModel)]="credentials.name" 
                #name="ngModel" required [ngClass]="{ 'is-invalid': !name.valid && name.touched, 'is-valid': name.valid && name.touched }">
                <div *ngIf="!name.valid && name.touched" class="text-danger">Ragione sociale richiesta!</div>
              </div>
            </div> 

            <!-- Mail --> 
            <div class="form-row">  
              <div class="col">
                <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="validationTooltipUsernamePrepend">@</span>
                  </div>
                  <input id="email" type="text" class="form-control" name="email" placeholder="Inserire una Mail" [(ngModel)]="credentials.email"
                  #mail="ngModel" 
                  [ngClass]="{ 
                    'is-invalid': mail.errors?.pattern || (mail.errors?.required && mail.touched) || mexMail, 
                    'is-valid': mail.valid && name.touched }"
                    required pattern="[a-z0-9._%+-]+@[a-z0-9-.]+\.[a-z]{2,4}$">
                </div> 
                  
                <div *ngIf="mail.errors?.pattern" class="text-danger">Formato e-Mail non corretto</div>
                <div *ngIf="mail.errors?.required && mail.touched" class="text-danger">e-Mail richiesta!</div>
                <div *ngIf="mexMail" class="text-danger">e-Mail esistente!</div>
              </div>
            </div>

            <!-- Password -->  
            <div class="form-row">    
              <div class="col">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="credentials.password"
                #password="ngModel" 
                [ngClass]="{ 'is-invalid': password.invalid && password.touched, 'is-valid':!password.invalid && password.touched}" required  minlength="6">
                <div *ngIf="password.errors?.required && password.touched " class="text-danger">Password richiesta!</div>
                <div *ngIf="password.errors?.minlength" class="text-danger">La password deve essere di almeno 6 caratteri</div>
              </div> 
            </div>

            <!-- Key-->
            <div class="form-row">
              <div class="col">
                <span class="input-group-addon"><i class="fas fa-key"></i></span>
                <input id="key" type="text" class="form-control" name="key" placeholder="Inserire KEY" [(ngModel)]="credentials.key"
                #key="ngModel"  [ngClass]="{ 'is-invalid': key.invalid && key.touched, 'is-valid': !key.invalid && key.touched }" required>
                <div *ngIf="key.errors?.required && key.touched" class="text-danger">Key richiesta!</div>                  
              </div>  
            </div>

            <!-- Button -->
            <div class="form-row">
              <div *ngIf="regForm.form.valid" class="col text-center">
                
                <button type="submit" class="btn btn-default" disable>Registra</button>
              </div>  
            </div>      
          </form>
        </div>
        <div class="card-footer text-center">Sei già registrato? Esegui il <a routerLink="/login">log in</a>.</div>
    </div> 
  </div>
</div>
</body>
</html> 
