<!--<nav class="navbar navbar-expand-lg navbar-dark bg-primary nav-margin-bottom">-->
<nav class="navbar navbar-expand-sm navbar-dark nav-margin-bottom" style="padding:0px; background-color: #007ba8;"> 
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent"
  style="
    margin-left: 5px; 
    font-size:15px;
    height: 50px
    ">
      
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#" [routerLink]="['/']" routerLinkActive="router-link-active" ><i class="fas fa-home fa-lg"></i> <span class="sr-only">(current)</span> BibliO.S. Home Page</a>
      </li>
      <li class="nav-item active">     
        <a  class="nav-link" [routerLink]="['/userguide']"  ><i class="fas fa-book fa-lg"></i> User Guide</a>
      </li>
      <li class="nav-item active">     
        <a  class="nav-link" [routerLink]="['/hedbloodtests']"  ><i class="fas fa-book fa-lg"></i> H&D Blood Tests</a>
      </li>
      <li class="nav-item active">     
        <a  class="nav-link" [routerLink]="" (click)="open(mymodal)" ><i class="far fa-envelope fa-lg"></i> Contact Us</a>
      </li>
      <li class="nav-item active">     
        <a  class="nav-link" [routerLink]="" (click)="open(mymodalShare)" ><i class="fas fa-share-alt-square"></i> Share Your Article</a>
      </li>
      
      
    </ul>
  </div>

</nav>

<ng-template #mymodal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Contact Us</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form (submit)="sendMail()" #regForm="ngForm">
      <div class="modal-body">

              <div class="form-row">
                  <div class="form-group col">
                      <h5>Your Address</h5>
                      <input id="email" type="text" class="form-control" name="email" [(ngModel)]="email"
                      placeholder="Enter your e-Mail address"
                      #mail="ngModel" 
                  [ngClass]="{ 
                    'is-invalid': mail.errors?.pattern || (mail.errors?.required && mail.touched), 
                    'is-valid': mail.valid }"
                    required pattern="[a-z0-9._%+-]+@[a-z0-9-.]+\.[a-z]{2,4}$" >
                  </div>                  
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <div *ngIf="mail.errors?.pattern" class="text-danger">e-Mail not valid!</div>
                  <div *ngIf="mail.errors?.required && mail.touched" class="text-danger">e-Mail required!</div>
                </div>
              </div> 

              <div class="form-row">
                <div class="form-group col">
                    <h5>Your Message</h5>
                    <textarea id="email" type="text" class="form-control" name="testoMail" [(ngModel)]="testoMail"
                    placeholder="Enter your feedback, question or request" rows="6" cols="30" 
                    #name="ngModel" required [ngClass]="{ 'is-invalid': !name.valid && name.touched, 'is-valid': name.valid && name.touched }">


                    </textarea>
                </div>
              </div> 
              <div class="form-row">
                <div class="form-group col">
                  <div *ngIf="!name.valid && name.touched" class="text-danger">Text required!!</div>
                </div>
              </div>

              <div class="form-row">
                  <div class="form-group col">
                      <div *ngIf="isShown">
                          <p style="color: forestgreen;"><b>email successfully sent!</b></p>
                      </div>   
                  </div>
              </div>             
      </div>
  
      <div class="modal-footer" style="justify-content: center;">
          <div class="row buttons-bottom-group" >   
              <div class="col text-center" *ngIf="!isShown"><!--*ngIf="regForm.form.valid"-->
                  <button type="submit" class="btn btn-success button-bottom" [disabled]="!regForm.form.valid">
                      <i class="fa fa-envelope size:3x text-grey" aria-hidden="true"></i>
                  </button>
              </div>    
          </div>
      </div>
  </form> 

</ng-template>

<ng-template #mymodalShare let-modal style="max-width: 800px;">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Share</h4>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
    
  </div>

  <form (submit)="shareArticle()" #regForm="ngForm">
      <div class="modal-body">
              <!-- Mail -------------------------------------------------------------------------------->
              <div class="form-row">
                  <div class="form-group col">
                      <h5>Your Address</h5>
                      <input id="email" type="text" class="form-control" name="email" [(ngModel)]="email"
                      placeholder="Enter your e-Mail address"
                      #mail="ngModel" 
                  [ngClass]="{ 
                    'is-invalid': mail.errors?.pattern || (mail.errors?.required && mail.touched), 
                    'is-valid': mail.valid }"
                    required pattern="[a-z0-9._%+-]+@[a-z0-9-.]+\.[a-z]{2,4}$" >
                  </div>                  
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <div *ngIf="mail.errors?.pattern" class="text-danger">e-Mail not valid!</div>
                  <div *ngIf="mail.errors?.required && mail.touched" class="text-danger">e-Mail required!</div>
                </div>
              </div> 
              
              <div class="form-row">
                <!-- Nome -------------------------------------------------------------------------------->
                <div class="form-group col">
                    <h5>Name*</h5>
                    <input type="text" id="email" class="form-control" name="nome" [(ngModel)]="nome"
                    placeholder="Enter your Name" rows="6" cols="30" 
                    #name="ngModel" required [ngClass]="{ 'is-invalid': !name.valid && name.touched, 'is-valid': name.valid && name.touched }">
                    
                    <div *ngIf="!name.valid && name.touched" class="text-danger">Name required!!</div>
                </div>
                <!-- Cognome -------------------------------------------------------------------------------->
                <div class="form-group col">
                  <h5>Surname*</h5>
                  <input type="text" id="email" class="form-control" name="cognome" [(ngModel)]="cognome"
                  placeholder="Enter your Surname" rows="6" cols="30" 
                  #surname="ngModel" required [ngClass]="{ 'is-invalid': !surname.valid && surname.touched, 'is-valid': surname.valid && surname.touched }">
                  <div *ngIf="!surname.valid && surname.touched" class="text-danger">Surname required!!</div>
                </div>
              </div> 

              <div class="form-row">
                <!-- Istituzione  -------------------------------------------------------------------------------->
                <div class="form-group col">
                    <h5>Institution</h5>
                    <input type="text" id="email" class="form-control" name="istitut" [(ngModel)]="istitut"
                    placeholder="Enter Field" rows="6" cols="30">
                </div>
              </div> 
             
              <div class="form-row">
                <!-- Titolo -------------------------------------------------------------------------------->
                <div class="form-group col">
                    <h5>Title*</h5>
                    <input type="text" id="email" class="form-control" name="titolo" [(ngModel)]="titolo"
                    placeholder="Enter Title" rows="6" cols="30" 
                    #titolovar="ngModel" required [ngClass]="{ 'is-invalid': !titolovar.valid && titolovar.touched, 'is-valid': titolovar.valid && titolovar.touched }">
                    
                    <div *ngIf="!titolovar.valid && titolovar.touched" class="text-danger">Field required!!</div>
                </div>
                <!-- Auto -------------------------------------------------------------------------------->
                <div class="form-group col">
                  <h5>Authors*</h5>
                  <input type="text" id="email" class="form-control" name="auth" [(ngModel)]="auth"
                  placeholder="Enter Authors" rows="6" cols="30" 
                  #authvar="ngModel" required [ngClass]="{ 'is-invalid': !authvar.valid && authvar.touched, 'is-valid': authvar.valid && authvar.touched }">
                  <div *ngIf="!authvar.valid && authvar.touched" class="text-danger">Field required!!</div>
                </div>
              </div> 

              <div class="form-row">
                <!-- Reference -------------------------------------------------------------------------------->
                <div class="form-group col">
                    <h5>Reference*</h5>
                    <input type="text" id="email" class="form-control" name="ref" [(ngModel)]="ref"
                    placeholder="Enter References" rows="6" cols="30" 
                    #refvar="ngModel" required [ngClass]="{ 'is-invalid': !refvar.valid && refvar.touched, 'is-valid': refvar.valid && refvar.touched }">
                    
                    <div *ngIf="!refvar.valid && refvar.touched" class="text-danger">Field required!!</div>
                </div>
                <!-- Auto -------------------------------------------------------------------------------->
                <div class="form-group col">
                  <h5>DOI</h5>
                  <input type="text" id="email" class="form-control" name="doi" [(ngModel)]="doi"
                  placeholder="Enter DOI" rows="6" cols="30">
                </div>
              </div> 


              <!-- MEX INVIO Mail -------------------------------------------------------------------------------->
              <div class="form-row">
                  <div class="form-group col">
                      <div *ngIf="isShown2">
                          <p style="color: forestgreen;"><b>email successfully sent!</b></p>
                      </div>   
                  </div>
              </div> 
              
              <br>
    Submit your article on oxidative stress and its implication in the medical field. BibliO.S. staff will review your article.

      </div>
  
      <div class="modal-footer" style="justify-content: center;">
          <div class="row buttons-bottom-group" >   
              <div class="col text-center" *ngIf="!isShown2"><!--*ngIf="regForm.form.valid"-->
                  <button type="submit" class="btn btn-success button-bottom" [disabled]="!regForm.form.valid">
                      <i class="fa fa-envelope size:3x text-grey" aria-hidden="true"></i>
                  </button>
              </div>    
          </div>
      </div>
  </form> 
  

</ng-template>


<!--<button type="button" class="close" aria-label="Close" (click)="leggiSerialPort()">Leggi porta seriale</button>-->
