
&nbsp; &nbsp; &nbsp; &nbsp; <input style="position:absolute" type="image" src="assets/image/flag_eng.png" (click)="changeLanguageReg('ita')"/>
&nbsp;<input style="position:absolute; margin-left: 35px;" type="image" src="assets/image/flag_ita.png" (click)="changeLanguageReg('eng')"/>&nbsp;
<div *ngIf="this.flagTextEng">
<div id="parentdiv" #parentdiv>
    <div class="row justify-content-center">
        <div class="col-md-8 register-employee">
            <div class="card card-outline-secondary">
                <div class="card-header">
                    <h1 >HOW TO USE BIBLIO.S.</h1>
                </div>

                <div class="card-body">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar" style='text-align:justify'> 

                        BibliO.S. is your bibliography research (“Bibli”) tool for <a target="_black"  href="https://www.hedsrl.it/eng/oxidative-stress/">Oxidative Stress</a> (“O.S.”) and its consequences in all medical fields. 
                        You can find over {{contArt}} documents among clinical studies, reviews, scientific articles, conference proceedings, 
                        thesis and other materials. You can search by keywords, authors, year or categories (medical fields). 
                        You may also find a link to the original publications, if available. We manually validated all references.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The Research Form</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_0.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                            You may combine any criteria:
                            <ol>
                                <li>search in the title of the documents</li>
                                <li>search for author (main or secondary)</li>
                                <li>search from the desired year</li>
                                <li>search up to the desired year</li>
                                <li>select the main category from the available list</li>
                                <li>select the secondary category to filter results from the main category</li>
                            </ol>

                            <b>A</b>: press to start the search<br>
                            <b>B</b>: reset all the form fields<br><br>

                            If you use multiple search fields at the same time, only the results corresponding to all the data required will be displayed (“AND” logic).
                            For example, by entering 2018 in field 3 and by selecting Oncology in field 6, only results relating to oncology from 2018 to today will be found.
                            Additionally, by selecting two different categories, only the results for both will be found. For example, to limit the search to diabetic heart patients, it could be useful to select Diabetology in field 6 and Cardiovascular Diseases in field 7.
                        </div>
                        <br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The Results</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_1.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                            All results will directly offer you some basic information:
                            <ol>
                                <li>document title</li>
                                <li>main / secondary authors; click on the main author to view all his publications</li>
                                <li>click the link to read the original document, if available online. If not, you will find a link to contact us</li>
                                <li>click to view some details: year, reference, published as, category and keywords (see next figure)</li>
                            </ol>
                            
                            <br><br>

                            <img src="assets/image/istruz_book_2.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">

                        </div>
                        <br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The Menu</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_3.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 50%;
                                    ">

                            In the top section of BibliO.S. you may find links to the most common tools:
                            <ol>
                                <li>click to return to BibliO.S. homepage</li>
                                <li>click to enter this guide</li>
                                <!--<li>click to submit your document to BibliO.S.</li>-->
                                <li>click to read H&D Blood Tests' history</li>
                                <li>click to contact us for feedback, requests or questions</li>
                                <li>click to submit your articles if you use H&D test</li>
                            </ol>
                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">The Contact Form</h3><br>
                        <div>
                            You may contact us for feedback, requests and questions. Please fill all the required fields in the contact form:
                        
                            <img src="assets/image/istruz_book_4.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                                <ol>
                                    <li>enter your valid email address</li>
                                    <!--<li>select your reason for contacting us</li>-->
                                    <li>enter your feedback, question or request</li>
                                    <li>click to send us your message</li>
                                </ol>



                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Share Your Article</h3><br>
                        <div>
                            In case you use H&D test we would like include your research paper (articles, act of congress, thesis...)  in BibliO.S. You just have to fill in the following form.
                            
                            <img src="assets/image/istruz_book_5.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                                <ol>
                                    <li>enter your data</li>
                                    <li>enter the scientific paper details</li> 
                                    <li>click to send us your message scientific paper</li>
                                </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
</div>
<div *ngIf="flagTextIta">
<div id="parentdiv" #parentdiv>
    <div class="row justify-content-center">
        <div class="col-md-8 register-employee">
            <div class="card card-outline-secondary">
                <div class="card-header">
                    <h1 >COME USARE BIBLIO.S.</h1>
                </div>

                <div class="card-body">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar" style='text-align:justify'> 

                        BibliO.S. è il vostro strumento di ricerca bibliografica ("Bibli") per <a target="_black" href="https://www.hedsrl.it/eng/oxidative-stress/">lo stress ossidativo</a> ("O.S.") e le sue conseguenze in tutti i campi della medicina. 
                        È possibile trovare oltre {{contArt}} documenti tra studi clinici, recensioni, articoli scientifici, atti di conferenze, 
                        tesi di laurea e altro materiale. È possibile effettuare una ricerca per parole chiave, autori, anno o categorie (campi medici). 
                        È inoltre possibile trovare un link alle pubblicazioni originali, se disponibili. Abbiamo convalidato manualmente tutti i riferimenti.
                        <br><br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Il form Ricerca</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_0.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                            Puoi combinare qualsiasi criterio:
                            <ol>
                                <li>cerca nel titolo dei documenti</li>
                                <li>cerca per autore (principale o secondario)</li>
                                <li>cerca a partire dall'anno desiderato</li>
                                <li>cerca fino all'anno desiderato</li>
                                <li>seleziona la categoria principale dall'elenco disponibile</li>
                                <li>seleziona la categoria secondaria per filtrare i risultati dalla categoria principale</li>
                            </ol>

                            <b>A</b>: premi per avviare la ricerca<br>
                            <b>B</b>: reimposta tutti i campi del modulo<br><br>

                            Se si utilizzano più campi di ricerca contemporaneamente verranno visualizzati solo i risultati corrispondenti a tutti i dati richiesti (logica “AND”).
                            Ad esempio, inserendo 2018 nel campo 3 e selezionando Oncologia nel campo 6 si troveranno solo i risultati relativi all'oncologia dal 2018 ad oggi.
                            Inoltre, selezionando due diverse categorie, verranno trovati solo i risultati di entrambe. Ad esempio, per limitare la ricerca ai cardiopatici diabetici, potrebbe essere utile selezionare Diabetologia nel campo 6 e Malattie Cardiovascolari nel campo 7.
                        </div>
                        <br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">I Risultati</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_1.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">
                            Tutti i risultati ti forniranno direttamente alcune informazioni di base:
                            <ol>
                                <li>titolo del documento</li>
                                <li>autori principali/secondari; clicca sull'autore principale per visualizzare tutte le sue pubblicazioni</li>
                                <li>fare clic sul collegamento per leggere il documento originale, se disponibile online. In caso contrario, troverai un link per contattarci</li>
                                <li>fai clic per visualizzare alcuni dettagli: anno, riferimento, pubblicato come, categoria e parole chiave (vedi figura successiva)</li>
                            </ol>
                            
                            <br><br>

                            <img src="assets/image/istruz_book_2.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 90%;
                                    ">

                        </div>
                        <br>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Il Menu</h3><br>
                        <div>
                            <img src="assets/image/istruz_book_3.png" style="
                                        margin: 10px auto 20px;
                                        display: block;
                                        max-width: 50%;
                                    ">

                        Nella sezione superiore di BibliO.S. potresti trovare i link agli strumenti più comuni:
                        <ol>
                        <li>clicca per tornare alla homepage di BibliO.S.</li>
                        <li>fai clic per accedere a questa guida</li>
                        <!--<li>fai clic per inviare il tuo documento a BibliO.S.</li>-->
                        <li>fai clic per leggere la storia dei test sul sangue di H&D</li>
                        <li>fai clic per contattarci per feedback, richieste o domande</li>
                        <li>fai clic per inviare i tuoi articoli se utilizzi il test H&D</li>
                        </ol>
                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Il form Contatti</h3><br>
                        <div>
                            Puoi contattarci per feedback, richieste e domande. Compila tutti i campi obbligatori del modulo di contatto:
                        
                            <img src="assets/image/istruz_book_4.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                                <ol>
                                    <li>inserisci il tuo indirizzo email valido</li>
                                    <!--<li>seleziona il motivo per cui ci contatti</li>-->
                                    <li>inserisci il tuo feedback, domanda o richiesta</li>
                                    <li>fai clic per inviarci il tuo messaggio</li>
                                </ol>



                        </div>

                        <h3 #chp1 style="text-align: center;color: rgb(103, 170, 197);">Condividi il tuo articolo</h3><br>
                        <div>
                            Nel caso utilizzi il test H&D vorremmo includere il tuo elaborato di ricerca (articoli, atti di congresso, tesi...) in BibliO.S. Devi solo compilare il seguente modulo.
                            
                            <img src="assets/image/istruz_book_5.png" style="
                                            margin: 10px auto 20px;
                                            display: block;
                                            max-width: 60%;
                                        ">

                                <ol>
                                    <li>inserisci i tuoi dati</li>
                                    <li>inserisci i dettagli dell'articolo scientifico</li>
                                    <li>fai clic per inviarci il tuo articolo scientifico</li>
                                </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>