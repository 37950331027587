import { Component, OnInit } from '@angular/core';
import { ApiBookService } from 'src/app/service/book/api-book.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contArt=0;

  constructor(private apiService: ApiBookService) {
    this.countArt()
   }

  ngOnInit(): void {
  }

  // Count articoli
  countArt(){

    let contArtString=""
    this.apiService.countArticle().subscribe((data) => {
      contArtString = JSON.stringify(data);
      this.contArt=Math.round(parseInt(contArtString,10)/100)*100;
     }) 
  }

}
