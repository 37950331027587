import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, TokenPayload } from 'src/app/service/authentication.service';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  credentials: TokenPayload = {
    email: '',
    name: '',
    password: '',
    key: ''
  };
   

  mexKey = false;
  mexMail = false;

  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    
  }

  register() {
    this.mexKey=false;
    this.mexMail=false;

    this.auth.register(this.credentials).subscribe(() => {
      this.router.navigateByUrl('/home');
    }, (err) => {
      if(err.error.message=="mail_not_valid"){
        console.log("Mail non valida");
        this.mexMail = true;
        //console.error(err.error.message);
      }
      if(err.error.message=="key_not_valid"){
        console.log("Chiave non valida");
        this.mexKey = true;
      }
    });
  }
}
