<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!--
  <app-nav-bar></app-nav-bar>
<div class="navbar navbar-default">
  <div class="container">
    <div id="navbar-main">
      <ul class="nav navbar-nav">
        <li><a routerLink="/">Home</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="!auth.isLoggedIn()"><a routerLink="/login">Sign in</a></li>
        <li *ngIf="auth.isLoggedIn()"><a routerLink="/profile">{{ auth.getUserDetails()?.name }}</a></li>
        <li *ngIf="auth.isLoggedIn()"><a (click)="auth.logout()">Logout</a></li>
      </ul>
    </div>
  </div>
</div>-->

  <app-nav-bar></app-nav-bar>
  <router-outlet></router-outlet>

<!--<app-footer></app-footer>-->