
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1">
<title>Bootstrap Flat Modal Login Modal Form</title>
<link href="https://fonts.googleapis.com/css?family=Roboto|Varela+Round" rel="stylesheet">
<style type="text/css">

</style>
</head>
<body>

<div id="myModal" >
	<div class="modal-dialog modal-login">
		<div class="modal-content">
			<div class="modal-header">				
				<h4 class="modal-title">Sign In</h4>
			</div>
			<div class="modal-body">          
          <form (submit)="login()">
            <div class="form-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input type="email" class="form-control" name="email" placeholder="Inserire Email" [(ngModel)]="credentials.email">
            </div>
            <div class="form-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="credentials.password">
            </div>
            <!--Mex Error User not found-->
            <div class="alert alert-danger" role="alert" *ngIf="mexUserFound">
              User non trovato
            </div>

            <div class="row">
              <div class="col text-center">
                <button type="submit" class="btn btn-default">Sign in!</button>
              </div>  
            </div>      
          </form>

					<p class="hint-text"><a routerLink="/resetpassword">Forgot Password?</a></p>
			</div>
			<div class="modal-footer">Don't have an account? <a routerLink="/register">Create one</a></div>
		</div>
  </div>
</div> 
</body>
</html> 
