import { Component, OnInit } from '@angular/core';
import { ApiBookService } from 'src/app/service/book/api-book.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-istruzioni-book',
  templateUrl: './istruzioni-book.component.html',
  styleUrls: ['./istruzioni-book.component.css']
})
export class IstruzioniBookComponent implements OnInit {
  
  langReg = 'eng';
  flagTextEng = true;
  flagTextIta = false;
  contArt=0;

  constructor(private apiService: ApiBookService,
    private actRoute: ActivatedRoute,
    private modalService: NgbModal,
    ) {
    this.countArt();
   }

  ngOnInit(): void {
  }

  changeLanguageReg(changeLang){

    if(changeLang)
    this.langReg=changeLang;
    console.log("LINGUA FLAG :"+changeLang+" | "+this.langReg)

    if(this.langReg=="ita"){
      this.langReg="eng";
      this.flagTextEng = true;
      this.flagTextIta = false;
      console.log("Siamo nella modalità inglese!! FlagTextIta:" +this.flagTextIta)
    } else if(this.langReg=="eng"){
      this.langReg="ita";
      this.flagTextEng = false;
      this.flagTextIta = true;
      console.log("Siamo nella modalità italiano!! FlagTextEng:" +this.flagTextEng)
    }

    console.log("NUOVA LINGUA: "+this.langReg)
  }
  // Count articoli
  countArt(){

    let contArtString=""
    this.apiService.countArticle().subscribe((data) => {
      contArtString = JSON.stringify(data);
      this.contArt=Math.round(parseInt(contArtString,10)/100)*100;
     }) 
  }

  
}
